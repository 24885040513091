<template>
	<v-card>
		<v-container fluid>
			<v-row>
				<v-col cols="4">
					<div class="d-flex">
						<v-btn @click="getInfo" color="primary" small>
							<v-icon left>mdi-reload</v-icon>Recargar
						</v-btn>
						<v-spacer />
						<v-btn @click="llamada = null" color="primary" small>
							<v-icon left>mdi-plus</v-icon>Apuntar nueva llamada
						</v-btn>
					</div>
					<v-list dense>
						<v-list-item-group v-model="llamada" color="primary">
							<v-list-item :value="item" v-for="item in data.llamadas" :key="item.idLlamada">
								<v-list-item-icon>
									<v-icon v-if="!!Number(item.respondida)" color="success">mdi-phone-check</v-icon>
									<v-icon v-else color="error">mdi-phone-alert</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-text="parseDate(item.fechaLlamada, false, true, true)"></v-list-item-title>
									<v-list-item-subtitle v-text="item.Nombre"></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>

				<v-divider vertical inset></v-divider>

				<v-col cols="8" v-if="llamada && Object.entries(llamada).length > 0">
					<v-subheader>DETALLES</v-subheader>
					<aux-input title="Fecha llamada">{{ parseDate(llamada.fechaLlamada, false, true, true) }}</aux-input>
					<aux-input title="Llamada realizada por">{{ llamada.Nombre }}</aux-input>
					<aux-input title="Respondida">{{ Number(llamada.respondida) ? 'Sí' : 'No' }}</aux-input>
					<aux-input title="Resumen llamada">{{ llamada.resumen }}</aux-input>
				</v-col>

				<v-col v-if="llamada === null && Object.entries(data).length > 0">
					<v-subheader>NUEVA LLAMADA</v-subheader>
					<aux-input title="Fecha llamada">
						<v-date-picker landscape v-model="nuevaLlamada.fecha"></v-date-picker>
					</aux-input>
					<aux-input title="Hora llamada">
						<v-time-picker landscape format="24hr" v-model="nuevaLlamada.hora"></v-time-picker>
					</aux-input>
					{{ parseDate(nuevaHora, false, true, true) }}
					<aux-input title="¿Respondida?">
						<v-radio-group row v-model="nuevaLlamada.respondida">
							<v-radio :value="true" label="Sí" dense hide-details></v-radio>
							<v-radio :value="false" label="No" dense hide-details></v-radio>
						</v-radio-group>
					</aux-input>
					<aux-input title="Resumen">
						<v-textarea v-model="nuevaLlamada.resumen" no-resize filled dense></v-textarea>
					</aux-input>

					<v-btn @click="guardar" color="primary" large>
						<v-icon left>mdi-content-save</v-icon>Guardar
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { parseDate, decode64 } from "@/utils/index.js";

export default {
	components: {
		TextEditor: () => import("@/components/TextEditor.vue"),
		DetallesContrato: () => import("@/modules/contratos/views/DetallesContrato.vue"),
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		AuxInput: () => import("@/components/AuxInput.vue"),
	},
	props: {
		IdCarteraCobro: String | Number,
	},
	computed: {
		nuevaHora() {
			if (!this.nuevaLlamada.hora || !this.nuevaLlamada.fecha) return

			const [h, m] = this.nuevaLlamada.hora.split(':')
			let date = new Date(this.nuevaLlamada.fecha);
			date.setHours(h, m);
			return date.getTime()
		}
	},
	data() {
		return {
			data: {},
			llamada: null,
			nuevaLlamada: {},
		};
	},
	methods: {
		parseDate,
		decode64,
		async getInfo() {
			const { data } = await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
				method: 'GET',
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdCarteraCobro: this.IdCarteraCobro
				}
			});
			this.data = data
			this.llamada = data.llamadas[0];
		},
		guardar() {
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/llamadas_impagados.php`,
				method: 'POST',
				data: {
					token: this.$store.getters.getJwtEmpresa,
					llamada: {
						IdCarteraCobro: this.IdCarteraCobro,
						resumen: this.nuevaLlamada.resumen,
						fechaLlamada: this.nuevaHora,
						respondida: this.nuevaLlamada.respondida
					}
				}
			}).then(res => { this.getInfo(); this.nuevaLlamada = {} });
		}
	},
	mounted() {
		this.getInfo();

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth();
		const year = date.getFullYear();
		this.$set(this.nuevaLlamada, 'fecha', [year, ('00' + month).slice(-2), ('00' + day).slice(-2)].join('-'))

		const hours = date.getHours();
		const minutes = date.getMinutes();
		this.$set(this.nuevaLlamada, 'hora', [('00' + hours).slice(-2), ('00' + minutes).slice(-2)].join(':'))
		// this.nuevaLlamada.fecha = ;
	}
};
</script>

<style>
.sticky {
	position: sticky;
	top: 0;
}
</style>